.tour-generation-picker {
  width: 640px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  padding-right: 20px;
  padding-bottom: 24px;
  padding-left: 20px;
  background: #020332;
  border-radius: 16px;

  .tour-generation-picker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tour-generation-picker-header-close {
      cursor: pointer;
      align-self: flex-start;
    }
  }

  .tour-generation-picker-content {
    display: flex;

    .btn {
      width: 100%;
      justify-content: center;
    }
  }
}
