.tour-generating-modal {
  width: 304px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 16px;
  background: #071331;
  border-radius: 16px;
  border: 5px solid #2248d033;

  .tour-generating-modal-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
