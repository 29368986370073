.templates-modal-wrapper {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  .template-picker-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    overflow-y: auto;
    background-color: var(--b-100);

    .template-picker-header {
      padding: 24px 20px;
      padding-bottom: 0px;
    }

    .templates-wrapper {
      padding: 24px 20px;
      max-width: 100%;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(162px, 1fr));
      grid-gap: 20px;

      .template-block {
        flex: 1 1 0;
        max-width: 224px;
        height: 220px;
        cursor: pointer;
        overflow: hidden;
        transition: all 200ms ease-in-out 0s;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        animation: fade-in 400ms ease-in-out both;
        border-radius: 16px;
        background: #fff;
        box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.04);
        outline: 2px solid transparent;

        @for $i from 1 through 10 {
          &:nth-child(#{$i}n) {
            animation-delay: #{(5 - 5 / ((3 + $i) * 0.3)) * 200}ms;
          }
        }

        &:hover {
          outline: 2px solid var(--Blue-300, #85b1ff);
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
        }

        .template-header {
          height: 120px;
          overflow: hidden;
          flex-shrink: 0;

          .img-wrapper {
            display: flex;
            flex: 1 1 auto;
            min-height: 0px;
            height: 120px;

            img {
              margin: auto;
              position: relative;
              z-index: 1;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }

        .content {
          z-index: 2;
          display: flex;
          padding: 12px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
        }
      }

      .start-from-scratch {
        display: flex;
        height: 220px;
        flex: 1 1 0;
        max-width: 224px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        position: relative;
        overflow: hidden;
        border-radius: 16px;
        background: #fff;
        box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.04);
        transition: all 200ms ease-in-out 0s;
        cursor: pointer;
        outline: 2px solid transparent;
        animation: fade-in 400ms ease-in-out both;

        &:hover {
          outline: 2px solid var(--Blue-300, #85b1ff);
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
        }

        img {
          z-index: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
        }

        .icon-wrapper {
          z-index: 1;
          display: flex;
          padding: 16px;
          align-items: flex-start;
          gap: 8px;
          border-radius: 16px;
          background: var(--surface-primary, #fff);
          box-shadow: 0px 5px 28px 0px rgba(0, 0, 0, 0.09);
          position: relative;

          i {
            font-size: 28px;
            color: var(--b-400);
          }

          .icon-plus-wrapper {
            display: flex;
            width: 24px;
            height: 24px;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: -4px;
            bottom: -4px;
            border-radius: 41px;
            background: var(--b-400);

            i {
              color: var(--white);
              font-size: 14px;
            }
          }
        }

        .text-wrapper {
          z-index: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 4px;
        }
      }

      .generate-tour-with-ai {
        display: flex;
        height: 220px;
        flex: 1 1 0;
        max-width: 224px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        position: relative;
        overflow: hidden;
        border-radius: 16px;
        box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.04);
        transition: all 200ms ease-in-out 0s;
        cursor: pointer;
        outline: 2px solid transparent;
        animation: fade-in 400ms ease-in-out both;
        background: radial-gradient(
          70.07% 70.07% at 50% 29.93%,
          #060a79 0%,
          #000120 100%
        );

        &:hover {
          outline: 2px solid var(--Blue-300, #85b1ff);
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
        }

        img {
          z-index: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
        }

        .icon-wrapper {
          z-index: 1;
          display: flex;
          padding: 16px;
          align-items: flex-start;
          gap: 8px;
          border-radius: 16px;
          background: var(--surface-primary, #fff);
          box-shadow: 0px 5px 28px 0px rgba(0, 0, 0, 0.09);
          position: relative;

          i {
            font-size: 28px;
            color: var(--b-400);
          }

          .icon-plus-wrapper {
            display: flex;
            width: 24px;
            height: 24px;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: -4px;
            bottom: -4px;
            border-radius: 41px;
            background: var(--b-400);

            i {
              color: var(--white);
              font-size: 14px;
            }
          }
        }

        .text-wrapper {
          z-index: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 4px;
          padding: 0 16px;
          color: var(--white);
          text-align: center;
        }
      }
    }
  }
}
